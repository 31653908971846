import request from "./request";

// 获取设备厂商列表信息
export const apiManufacturerList = (data) => {
  return request({
    url: "/monitor/manufacturer/list",
    method: "POST",
    data,
  });
};
// 新增设备厂商
export const apiManufacturerAdd = (data) => {
  return request({
    url: "/monitor/manufacturer",
    method: "POST",
    data,
  });
};
// 更新设备厂商
export const apiManufacturerUpdate = (data) => {
  return request({
    url: "/monitor/manufacturer",
    method: "PUT",
    data,
  });
};
